.imageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-left: 0px;
}

.carouselImage {
  max-width: 95%;
  max-height: 95%;
  object-fit: contain;
  margin-bottom: 10px;
}

.activeImage {
  border: 0px solid blue;
}

@media (max-width: 768px) {
  .imageContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin-left: 0px;
  }

  .carouselImage {
    max-width: 95%;
    max-height: 95%;
    object-fit: contain;
    margin-bottom: 10px;
  }
}
