.layoutContainer {
  display: flex;
  flex-direction: row;
  min-height: calc(100vh - 50px - 50px);
}

.header {
  position: relative;
  z-index: 10;
  width: 100%;
  height: 50px;
}

.sidebar {
  position: fixed;
  top: 50px;
  left: 0;
  width: 200px;
  bottom: 30px;
  z-index: 5;
}

.mainContent {
  flex-grow: 1;
  margin-top: 30px;
  margin-left: 250px;
  margin-right: 0px;
}

.footer {
  position: relative;
  z-index: 10;
  width: 100%;
  height: 30px;
}

@media (max-width: 768px) {
  .header {
    position: relative;
    z-index: 10;
    width: 100%;
    height: 50px;
    margin-top: 60px;
    margin-left: 20px;
  }

  .mainContent {
    flex-grow: 1;
    margin-top: 30px;
    margin-left: 0px;
    margin-right: 0px;
  }
}
